import React from 'react';
import { AddCircleOutline } from '@mui/icons-material';
import { motion } from 'framer-motion';

const itemVariants = {
    hover: { scale: 1.05, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' },
    tap: { scale: 0.95 },
};

export const AddTaskButton = ({ className = '', iconProps = {}, openTaskModal }) => {
    const handleClick = () => {
        openTaskModal();
    };

    return (
        <motion.button
            className={`p-4 text-white bg-green-500 rounded-full add-task-button ${className}`}
            title="Create a new task"
            onClick={handleClick}
            // variants={itemVariants}
            whileHover="hover"
            whileTap="tap"
        >
            <AddCircleOutline {...iconProps} />
        </motion.button>
    );
};

export default AddTaskButton;
