import React, { useState, useEffect } from 'react';
import { TextField, Button, Snackbar, CircularProgress, Typography, Box, Container } from '@mui/material';
import { useMutation } from 'react-query';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';

const EmailForm = ({ setError, clearError }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD');
        ReactGA.send({ hitType: 'pageview', page: '/emailform', title: 'Email Form' });
    }, []);

    const clearErrors = () => {
        setApiError(false);
        setMobileError(false);
        clearError(); // Clear error in GettingStarted component
    };

    const generateMagicLinkMutation = useMutation(
        async ({ email, device }) => {
            setLoading(true);

            try {
                const response = await axios.post(baseUrl + 'api/generateMagicLink', {
                    email: email,
                    device: device,
                });

                console.log('[EmailForm] Magic link API response status:', response.status);

                if (response.status !== 200) {
                    console.error('[EmailForm] Non-200 response for magic link:', response.status);
                    throw new Error('Failed to send magic link');
                }

                console.log('[EmailForm] Magic link generated successfully');
            } catch (error) {
                console.error('[EmailForm] Error generating magic link:', error);
                console.error('[EmailForm] Error response:', error.response);

                if (error.response && error.response.status === 404 && (device === 'ios' || device === 'android')) {
                    console.log('[EmailForm] Mobile device error detected');
                    setMobileError(true);
                } else {
                    console.log('[EmailForm] Setting general API error');
                    setApiError(true);
                }
                throw error;
            } finally {
                setLoading(false);
            }
        },
        {
            onSuccess: () => {
                console.log('[EmailForm] Magic link mutation succeeded');
                setSubmitted(true);
                clearErrors();
                ReactGA.event({
                    category: 'User',
                    action: 'Signed Up',
                    label: 'Signed up',
                });
            },
            onError: (error) => {
                console.error('[EmailForm] Magic link mutation failed:', error);
                setApiError(true);
                setError(error.message || 'An error occurred. Please try again later.');
            },
        }
    );

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        clearErrors(); // Clear errors on form submission
        if (email === 'appletester@test.com') {
            setShowPasswordForm(true);
        } else {
            const platform = Capacitor.getPlatform();
            let device = platform === 'android' ? 'android' : platform === 'ios' ? 'ios' : 'web';
            generateMagicLinkMutation.mutate({ email, device });
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            console.log('[EmailForm] Making API request to:', baseUrl + 'api/retrieveTestToken');
            const response = await axios.post(baseUrl + 'api/retrieveTestToken', {
                email: email,
                password: password,
            });

            if (response.status !== 200) {
                console.error('[EmailForm] Non-200 response:', response.status);
                throw new Error('Failed to retrieve test token');
            }

            const data = response.data;

            navigate(`/magic-login?directtoken=${data.token}`);
        } catch (error) {
            console.error('[EmailForm] Error during password submission:', error);
            console.error('[EmailForm] Error response:', error.response);
            console.error('[EmailForm] Error status:', error.response?.status);
            console.error('[EmailForm] Error data:', error.response?.data);

            setApiError(true);
            const errorMessage =
                error.response?.data?.message || error.message || 'An error occurred. Please try again later.';
            console.error('[EmailForm] Setting error message:', errorMessage);
            setError(errorMessage);
        } finally {
            console.log('[EmailForm] Password submission process completed');
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="md">
            {submitted ? (
                <Box className="font-bold text-center text-black" sx={{ mt: 2, mb: 3 }}>
                    <Typography variant="h5" color="textPrimary">
                        A magic link has been sent to your email address. Please check your inbox and follow the link to
                        sign in{submitted && !loading ? '!' : '.'}
                    </Typography>
                </Box>
            ) : (
                <>
                    {mobileError && (
                        <Box
                            className="relative px-4 py-3 mb-4 text-yellow-700 bg-yellow-100 rounded border border-yellow-400"
                            role="alert"
                        >
                            <strong className="font-bold">Oops!</strong>
                            <span className="block sm:inline">
                                Please use the web/desktop version to create a new account.
                            </span>
                        </Box>
                    )}

                    <Snackbar
                        open={apiError}
                        autoHideDuration={6000}
                        onClose={() => setApiError(false)}
                        message="An error occurred. Please try again later."
                    />

                    <Box
                        className="justify-center w-full m-auto text-center sm:mt-[80px] mt-[15px] font-semibold mb-4"
                        sx={{ mt: 2, mb: 3 }}
                    >
                        Enter your email to receive your magic link and start your journey with Mind Your Now!
                    </Box>

                    <form onSubmit={handleSubmit}>
                        <Box className="flex flex-col items-center" sx={{ mt: 2, mb: 3 }}>
                            <TextField
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleChange}
                                required
                                fullWidth
                                variant="outlined"
                                className="mb-4 bg-white shadow-md"
                                placeholder="Email address"
                            />
                            {!showPasswordForm && (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="w-full shadow-md"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Send Magic Link'}
                                </Button>
                            )}
                        </Box>
                    </form>

                    {showPasswordForm && (
                        <form onSubmit={handlePasswordSubmit}>
                            <Box className="flex flex-col items-center" sx={{ mt: 2, mb: 3 }}>
                                <TextField
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    className="mb-4 bg-white shadow-md"
                                    placeholder="Password"
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="w-full shadow-md"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    )}

                    <Box mb={3} sx={{ textAlign: 'center', fontStyle: 'italic' }}>
                        <Typography variant="body1" color="textSecondary">
                            We respect your privacy and don't sell or share your email with anyone.
                        </Typography>
                    </Box>
                </>
            )}
        </Container>
    );
};

export default EmailForm;
