import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, IconButton, Avatar, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useTaskModal } from '../../contexts/TaskModalContext';
import { useTasks } from '../../providers/TasksProvider';
import { ScheduleSend, CancelScheduleSend } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { PriorityPanel } from '../tasks/PriorityPanel';
import { RRule } from 'rrule';
import moment from 'moment';

function getDuration(task) {
    if (task.duration === -1 || task.duration === '-1') {
        switch (task.priority) {
            case 'CRITICAL':
                return '30 minutes';
            case 'OPPORTUNITY_NOW':
                return '1 hour';
            case 'OVER_THE_HORIZON':
                return '3 hours';
            case 'PARKING_LOT':
                return '4 hours';
            default:
                return '15 minutes';
        }
    }

    if (typeof task.duration === 'string') {
        // Handle cases like "2h", "1h", and "30m" directly
        if (task.duration.endsWith('h')) {
            const hours = parseInt(task.duration);
            return hours === 1 ? '1 hour' : `${hours} hours`;
        }
        if (task.duration.endsWith('m')) {
            const minutes = parseInt(task.duration);
            return minutes === 1 ? '1 minute' : `${minutes} minutes`;
        }

        // Parse the duration string for more complex cases
        const durationRegex = /^PT?(\d+H)?(\d+M)?$/i;
        const match = task.duration.match(durationRegex);
        if (match) {
            const hours = match[1] ? parseInt(match[1]) : 0;
            const minutes = match[2] ? parseInt(match[2]) : 0;
            if (hours > 0 && minutes > 0) {
                return `${hours}h ${minutes}m`;
            } else if (hours > 0) {
                return hours === 1 ? '1 hour' : `${hours} hours`;
            } else if (minutes > 0) {
                return minutes === 1 ? '1 minute' : `${minutes} minutes`;
            }
        }
    }

    console.log('Duration:', task.duration);
    return 'Invalid duration';
}

export function getNextOccurrence(task) {
    if (task.futureEvents && task.futureEvents.length > 0) {
        return task.futureEvents.reduce((earliest, event) =>
            new Date(event.start) < new Date(earliest.start) ? event : earliest
        );
    }
    return null;
}

export function formatNextOccurrence(nextOccurrence) {
    if (!nextOccurrence || !nextOccurrence.start) {
        return 'Not scheduled';
    }
    try {
        const date = moment(nextOccurrence.start);
        const now = moment();

        if (date.isSame(now, 'day')) {
            return `Today at ${date.format('h:mma')}`;
        } else if (date.isSame(now.add(1, 'day'), 'day')) {
            return `Tomorrow at ${date.format('h:mma')}`;
        } else if (date.isSame(now, 'week')) {
            return date.format("dddd 'at' h:mma");
        } else {
            return date.format("MMM D 'at' h:mma");
        }
    } catch (error) {
        console.error('Error formatting next occurrence:', error);
        return 'Invalid date';
    }
}

function getPriorityColor(priority) {
    switch (priority) {
        case 'CRITICAL':
            return '#0C803D';
        case 'OPPORTUNITY_NOW':
            return '#107CC4';
        case 'OVER_THE_HORIZON':
            return '#F9913B';
        case 'PARKING_LOT':
            return '#F04F23';
        default:
            return '#9e9e9e';
    }
}

export function formatRecurrenceRule(rule) {
    if (!rule) return 'Not set';

    const rrule = RRule.fromString(rule);
    const freq = rrule.options.freq;
    const interval = rrule.options.interval || 1;
    const byweekday = rrule.options.byweekday;

    switch (freq) {
        case RRule.DAILY:
            return interval === 1 ? 'Every day' : `Every ${interval} days`;
        case RRule.WEEKLY:
            if (byweekday && byweekday.length > 0) {
                const days = byweekday
                    .map((day) => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day])
                    .join(', ');
                return interval === 1 ? `Every week on ${days}` : `Every ${interval} weeks on ${days}`;
            }
            return interval === 1 ? 'Every week' : `Every ${interval} weeks`;
        case RRule.MONTHLY:
            return interval === 1 ? 'Every month' : `Every ${interval} months`;
        case RRule.YEARLY:
            return interval === 1 ? 'Every year' : `Every ${interval} years`;
        default:
            return rule;
    }
}

function HabitCard({ task }) {
    const navigate = useNavigate();
    const { openTaskModal } = useTaskModal();
    const { saveTask } = useTasks();
    const [showPriorityPanel, setShowPriorityPanel] = useState(false);

    const handleEditClick = (e) => {
        e.stopPropagation();
        openTaskModal(task);
    };

    const toggleScheduling = (event) => {
        event.stopPropagation();
        const updatedTask = {
            ...task,
            isAutoScheduled: !task.isAutoScheduled,
            schedulingState: !task.isAutoScheduled ? 'scheduling_requested' : 'scheduling_cancel_requested',
        };
        saveTask(updatedTask);
    };

    const handlePriorityClick = (e) => {
        e.stopPropagation();
        setShowPriorityPanel(true);
    };

    const nextOccurrence = getNextOccurrence(task);
    const formattedNextOccurrence = formatNextOccurrence(nextOccurrence);
    const priorityColor = getPriorityColor(task.priority);

    return (
        <>
            {showPriorityPanel && (
                <div
                    className="flex fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50"
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            setShowPriorityPanel(false);
                        }
                    }}
                >
                    <PriorityPanel task={task} notifyClose={() => setShowPriorityPanel(false)} />
                </div>
            )}
            <Card
                sx={{
                    maxWidth: 345,
                    borderRadius: 2,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    '&:hover': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        cursor: 'pointer',
                    },
                    borderLeft: `4px solid ${priorityColor}`,
                    position: 'relative',
                }}
                onClick={() => navigate(`/habits/${task.id}`)}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 1,
                    }}
                    onClick={handleEditClick}
                >
                    <EditIcon />
                </IconButton>
                <CardContent>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {task.title}
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="body2" color="text.secondary">
                            <Box component="span" fontWeight="bold" display="inline-block" width="80px">
                                Duration:
                            </Box>
                            {getDuration(task)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <Box component="span" fontWeight="bold" display="inline-block" width="80px">
                                Repeat:
                            </Box>
                            {formatRecurrenceRule(task.recurrenceRule)}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Chip
                            variant="outlined"
                            label={!task.isAutoScheduled ? 'Schedule' : 'Cancel/Pause'}
                            size="small"
                            color="primary"
                            icon={!task.isAutoScheduled ? <ScheduleSend /> : <CancelScheduleSend />}
                            onClick={toggleScheduling}
                        />
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2" color="text.secondary">
                            <Box component="span" fontWeight="bold" display="inline-block" width="80px">
                                Next:
                            </Box>
                            {formattedNextOccurrence}
                        </Typography>
                        <IconButton sx={{ padding: '4px' }} onClick={handlePriorityClick}>
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                style={{ color: priorityColor, fontSize: '1.2rem' }}
                                title="Change priority"
                            />
                        </IconButton>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}

export default HabitCard;
export { getDuration, getPriorityColor };
