import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const TaskDisplay = ({ task, onComplete, openPriorityPanel }) => {
    const priorityColor = task.priority === 'PARKING_LOT' || task.priority === 'OVER_THE_HORIZON' ? 'red' : 'gray';

    return (
        <div className="flex">
            <FontAwesomeIcon
                className="mr-3 text-3xl cursor-pointer pointer-events-auto"
                color={!task.isCompleted ? 'gray' : 'red'}
                data-bs-toggle="tooltip"
                title="Mark Task Complete"
                icon={faCheckCircle}
                onClick={(e) => onComplete(e)}
            />
            <div
                className={
                    'p-0 text-lg capitalize items-center text-midnightblu-400 font-normal text-ellipsis font-roboto text-truncate overflow-hidden truncate w-max-full' +
                    (task.isCompleted?.toString() === 'true' ? ' strike ' : ' ')
                }
            >
                {task.title}
            </div>
            <div className="flex flex-row flex-grow justify-end m-0 ml-5">
                {!task.isCompleted && (
                    <FontAwesomeIcon
                        id="ChangeTaskPriority"
                        className="mr-4 text-3xl cursor-pointer pointer-events-auto changeTaskPriorityClass"
                        color={priorityColor}
                        data-bs-toggle="tooltip"
                        title="Change priority"
                        icon={faExclamationTriangle}
                        onClick={(e) => openPriorityPanel(e, task)}
                    />
                )}
            </div>
        </div>
    );
};

export default TaskDisplay;
