// React and React-related libraries
import React, { useCallback, useState, memo, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

// Third-party libraries
import { addDays, differenceInDays, isWithinInterval, startOfDay } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { Capacitor } from '@capacitor/core';
import { useDrag } from 'react-dnd';
import { ScheduleSend, CancelScheduleSend } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import ForwardIcon from '@mui/icons-material/Forward';
import { Tooltip } from '@mui/material';

// Local components
import TaskComponent from '../tasks/TaskComponent';
import { TaskScheduleStatus } from '../tasks/TaskScheduleStatus';

// Local utilities, hooks, and other functions
import { usePlanning } from '../../hooks/usePlanning';
import { useTaskModal } from '../../contexts/TaskModalContext';
import { useTasks } from '../../providers/TasksProvider';
import { useTaskState } from '../tasks/hooks/useTaskState';
import { useTaskSchedule } from '../tasks/hooks/useTaskSchedule';
import { useDeviceType } from '../../hooks/useDeviceType';

// Local constants and types

// Styles and assets

const MemoizedFontAwesomeIcon = memo(FontAwesomeIcon);

export const CompletedIcon = memo(({ isCompleted, onComplete }) => {
    const iconColor = useMemo(() => (isCompleted ? 'green' : 'gray'), [isCompleted]);
    const icon = faCheckCircle;

    const memoizedOnClick = useCallback(onComplete, [onComplete]);

    return (
        <MemoizedFontAwesomeIcon
            className="mr-1 text-3xl cursor-pointer pointer-events-auto"
            color={iconColor}
            data-bs-toggle="tooltip"
            title="Mark Task Complete"
            icon={icon}
            onClick={memoizedOnClick} // Use the memoized onClick callback
        />
    );
});

CompletedIcon.propTypes = {
    isCompleted: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null, undefined])]),
    onComplete: PropTypes.func.isRequired,
};

const DayRowTask = React.memo(
    React.forwardRef(({ task: initialTask, openPriorityPanel, isPlanner, isCalendarView }, ref) => {
        const { task, updateTask } = useTaskState(initialTask);
        const { saveTask, deleteTask } = useTasks(); // Use context for saveTask and deleteTask
        const { selectedTask, selectTask } = useTasks();
        const { plan, isPlanning } = usePlanning();
        const { openTaskModal } = useTaskModal();
        const [touchStartY, setTouchStartY] = useState(null);

        const taskSchedule = useTaskSchedule();

        const [{ isDragging }, drag, preview] = useDrag({
            type: 'TASK',
            item: { type: 'TASK', id: task.id },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        });

        const isOnCalendar = task.googleEvent?.googleStart && task.googleEvent?.googleEnd;

        const [isScheduleChipActive, setIsScheduleChipActive] = useState(task.isAutoScheduled);
        const isSelected = selectedTask && selectedTask.id === task.id;

        const onComplete = useCallback(
            (e) => {
                e.stopPropagation();
                const updatedTask = {
                    ...task,
                    isCompleted: !task.isCompleted,
                    completedDate: !task.isCompleted ? new Date() : null,
                    isAutoScheduled: task.isCompleted ? task.isAutoScheduled : false,
                };
                updateTask(updatedTask);
                saveTask(updatedTask);
            },
            [task, updateTask, saveTask]
        );

        const toggleScheduling = useCallback(
            (event) => {
                event.stopPropagation();
                const updatedTask = {
                    ...task,
                    isAutoScheduled: !task.isAutoScheduled,
                    schedulingState: !task.isAutoScheduled ? 'scheduling_requested' : 'scheduling_cancel_requested',
                };
                updateTask(updatedTask);
                saveTask(updatedTask);
                setIsScheduleChipActive(!task.isAutoScheduled);
                plan();
            },
            [task, updateTask, saveTask, plan]
        );

        // Add this useEffect to keep isScheduleChipActive in sync with task.isAutoScheduled
        useEffect(() => {
            setIsScheduleChipActive(task.isAutoScheduled);
        }, [task.isAutoScheduled]);

        const taskIsCurrent =
            task.googleEvent &&
            isWithinInterval(new Date(), {
                start: new Date(task.googleEvent.googleStart.dateTime),
                end: new Date(task.googleEvent.googleEnd.dateTime),
            });

        let colorCode = 'border-red-400';
        switch (task.priority) {
            case 'CRITICAL':
                colorCode = 'border-myncritical-400';
                break;
            case 'OPPORTUNITY_NOW':
                colorCode = 'border-mynopportunitynow-400';
                break;
            case 'OVER_THE_HORIZON':
                colorCode = 'border-mynoverthehorizon-400';
                break;
            case 'PARKING_LOT':
                colorCode = 'border-mynparkinglot-400';
                break;
        }

        const getFutureDate = (priority) => {
            const tomorrow = addDays(new Date(), 1);

            switch (priority) {
                case 'CRITICAL':
                    return tomorrow;
                case 'OVER_THE_HORIZON':
                    return taskSchedule?.overTheHorizonDate;
                case 'OPPORTUNITY_NOW':
                    return taskSchedule?.opportunityNowDate;
                case 'PARKING_LOT':
                    return taskSchedule?.parkingLotDate;
                case 'TOMORROW':
                    return tomorrow;
                default:
                    console.error('Unrecognized task priority:', priority);
                    return null;
            }
        };

        const handleKickToFuture = useCallback(
            (e) => {
                e.stopPropagation();
                const futureDate = getFutureDate(task.priority);

                if (futureDate) {
                    const updatedTask = { ...task, startDate: futureDate };
                    updateTask(updatedTask);
                    saveTask(updatedTask);
                }
            },
            [task, updateTask, saveTask]
        );

        const calculateDaysToFuture = () => {
            const futureDate = getFutureDate(task.priority);
            if (futureDate) {
                const now = startOfDay(new Date());
                const future = startOfDay(new Date(futureDate));
                const days = differenceInDays(future, now);
                return `Kick ${days} days into the future`;
            } else {
                return 'Kick to Future';
            }
        };

        const cleanColorCode = useMemo(() => 'text-' + colorCode.replace('border-', ''), [colorCode]);

        const handleClick = useCallback(
            (e) => {
                e.stopPropagation();
                if (task.isRecurringChild) {
                    selectTask(task.parentTask.id);
                    openTaskModal(task.parentTask);
                } else {
                    selectTask(task.id);
                }
            },
            [task, selectTask, openTaskModal]
        );

        const handleTouchStart = (e) => {
            setTouchStartY(e.touches[0].clientY);
        };

        const handleTouchEnd = (e) => {
            if (touchStartY !== null) {
                const touchEndY = e.changedTouches[0].clientY;
                const diff = Math.abs(touchEndY - touchStartY);
                if (diff < 10) {
                    selectTask(task.id);
                }
            }
            setTouchStartY(null);
        };

        const memoizedOpenPriorityPanel = useCallback(openPriorityPanel, []);
        const memoizedNotifyDone = useCallback(() => {}, []);

        const { isMobile } = useDeviceType();

        return (
            <div
                ref={(node) => {
                    ref(node);
                    preview(node);
                }}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                <div className={`flex flex-row justify-between briefing`}>
                    {isSelected ? (
                        <div className={!Capacitor.isNativePlatform() ? 'ml-9' : 'ml-[-7]'}>
                            <TaskComponent
                                task={task}
                                childTask={task}
                                numberInList={0}
                                key={task.id}
                                notifyDone={memoizedNotifyDone}
                                openPriorityPanel={(e) => memoizedOpenPriorityPanel(e, task)}
                                onComplete={onComplete}
                            />
                        </div>
                    ) : (
                        <button
                            className={`flex items-center justify-between max-w-full hover:bg-gray-200 ${taskIsCurrent ? 'bg-green-100' : ''}`}
                            style={{ width: '100%', textAlign: 'left' }}
                            onClick={handleClick}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleClick(e);
                                }
                            }}
                        >
                            {isPlanner && !isCalendarView && (
                                <div className="drag-area" ref={drag} style={{ padding: '5px' }}>
                                    <div
                                        className={`flex items-center mr-3 justify-between ${colorCode.replace('border-', 'text-')}`}
                                    >
                                        <MemoizedFontAwesomeIcon
                                            className="text-2xl cursor-move"
                                            icon={faGripVertical}
                                        />
                                    </div>
                                </div>
                            )}

                            <CompletedIcon isCompleted={task.isCompleted} onComplete={onComplete} />
                            <div className={'ml-0 h-12 border-l-4 border-green-400 border-solid ' + colorCode}></div>
                            <div className="overflow-hidden flex-col">
                                <div
                                    className={`text-black flex-1 ml-1 font-normal overflow-hidden text-lg truncate text-ellipsis font-sfCompactDisplay ${task.isCompleted ? 'strike' : ''}`}
                                >
                                    {task.title}
                                </div>

                                <TaskScheduleStatus task={task} isOnCalendar={isOnCalendar} />
                            </div>

                            <div className="inline-flex justify-end items-center ml-auto h-12">
                                {task.isCompleted === false || (task.isCompleted === null && !task.parentTask) ? (
                                    <ForwardIcon
                                        className={'mr-3 text-2xl cursor-pointer ' + cleanColorCode}
                                        titleAccess={calculateDaysToFuture()}
                                        onClick={handleKickToFuture}
                                    />
                                ) : null}

                                <div
                                    className="inline-flex justify-end items-center ml-auto h-12"
                                    style={{ pointerEvents: 'auto' }}
                                >
                                    {!task.isCompleted && (
                                        <Tooltip
                                            title={
                                                task.isAutoScheduled
                                                    ? 'Click to disable automatic scheduling for this task'
                                                    : 'Click to enable automatic scheduling for this task'
                                            }
                                            arrow
                                            placement="top"
                                            PopperProps={{
                                                style: { pointerEvents: 'auto' },
                                            }}
                                        >
                                            <Chip
                                                variant="outlined"
                                                label={
                                                    !isScheduleChipActive
                                                        ? !isMobile && !isPlanner
                                                            ? 'Schedule'
                                                            : ''
                                                        : ''
                                                }
                                                size="medium"
                                                color="info"
                                                icon={isScheduleChipActive ? <CancelScheduleSend /> : <ScheduleSend />}
                                                sx={{
                                                    padding: '2px',
                                                    borderRadius: '9999px',
                                                    backgroundColor: 'white !important',
                                                    borderColor: task.isAutoScheduled
                                                        ? (() => {
                                                              switch (task.priority) {
                                                                  case 'CRITICAL':
                                                                      return '#0C803D';
                                                                  case 'OPPORTUNITY_NOW':
                                                                      return '#107CC4';
                                                                  case 'OVER_THE_HORIZON':
                                                                      return '#F9913B';
                                                                  case 'PARKING_LOT':
                                                                      return '#F04F23';
                                                                  default:
                                                                      return '#6b7280';
                                                              }
                                                          })()
                                                        : '#6b7280',
                                                    '& .MuiChip-label': {
                                                        color: task.isAutoScheduled
                                                            ? (() => {
                                                                  switch (task.priority) {
                                                                      case 'CRITICAL':
                                                                          return '#0C803D';
                                                                      case 'OPPORTUNITY_NOW':
                                                                          return '#107CC4';
                                                                      case 'OVER_THE_HORIZON':
                                                                          return '#F9913B';
                                                                      case 'PARKING_LOT':
                                                                          return '#F04F23';
                                                                      default:
                                                                          return '#6b7280';
                                                                  }
                                                              })()
                                                            : '#6b7280',
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        color: task.isAutoScheduled
                                                            ? (() => {
                                                                  switch (task.priority) {
                                                                      case 'CRITICAL':
                                                                          return '#0C803D';
                                                                      case 'OPPORTUNITY_NOW':
                                                                          return '#107CC4';
                                                                      case 'OVER_THE_HORIZON':
                                                                          return '#F9913B';
                                                                      case 'PARKING_LOT':
                                                                          return '#F04F23';
                                                                      default:
                                                                          return '#6b7280';
                                                                  }
                                                              })()
                                                            : '#6b7280',
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'white !important',
                                                        '& .MuiChip-label': {
                                                            color: task.isAutoScheduled
                                                                ? (() => {
                                                                      switch (task.priority) {
                                                                          case 'CRITICAL':
                                                                              return '#0C803D';
                                                                          case 'OPPORTUNITY_NOW':
                                                                              return '#107CC4';
                                                                          case 'OVER_THE_HORIZON':
                                                                              return '#F9913B';
                                                                          case 'PARKING_LOT':
                                                                              return '#F04F23';
                                                                          default:
                                                                              return '#6b7280';
                                                                      }
                                                                  })()
                                                                : '#6b7280',
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: task.isAutoScheduled
                                                                ? (() => {
                                                                      switch (task.priority) {
                                                                          case 'CRITICAL':
                                                                              return '#0C803D';
                                                                          case 'OPPORTUNITY_NOW':
                                                                              return '#107CC4';
                                                                          case 'OVER_THE_HORIZON':
                                                                              return '#F9913B';
                                                                          case 'PARKING_LOT':
                                                                              return '#F04F23';
                                                                          default:
                                                                              return '#6b7280';
                                                                      }
                                                                  })()
                                                                : '#6b7280',
                                                        },
                                                    },
                                                    pointerEvents: 'auto',
                                                    touchAction: 'none',
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    toggleScheduling(e);
                                                }}
                                                onTouchStart={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                onTouchEnd={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    toggleScheduling(e);
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                    {!task.isCompleted && (
                                        <MemoizedFontAwesomeIcon
                                            id="ChangeTaskPriority"
                                            className={
                                                'ml-3 changeTaskPriorityClass text-3xl cursor-pointer hover:text-green-700 pointer-events-auto ' +
                                                (isSelected ? 'mr-0' : 'mr-1')
                                            }
                                            color={'orange'}
                                            data-bs-toggle="tooltip"
                                            title="Change priority"
                                            icon={faExclamationTriangle}
                                            onClick={(e) => memoizedOpenPriorityPanel(e, task)}
                                        />
                                    )}
                                </div>
                            </div>
                        </button>
                    )}
                </div>
            </div>
        );
    })
);

DayRowTask.propTypes = {
    task: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isCompleted: PropTypes.bool,
        parentTask: PropTypes.object,
        googleEvent: PropTypes.shape({
            googleStart: PropTypes.shape({
                dateTime: PropTypes.string,
            }),
            googleEnd: PropTypes.shape({
                dateTime: PropTypes.string,
            }),
        }),
        priority: PropTypes.string,
        title: PropTypes.string,
        isAutoScheduled: PropTypes.bool,
        startDate: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
            PropTypes.func,
            PropTypes.any,
        ]),
    }).isRequired,
    openPriorityPanel: PropTypes.func.isRequired,
    isPlanner: PropTypes.bool.isRequired,
    isCalendarView: PropTypes.bool.isRequired,
};

export default DayRowTask;
