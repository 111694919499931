import { Capacitor } from '@capacitor/core';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isReschedulingAtom } from './atoms/isReschedulingAtom';
import { useCustomer } from './providers/CustomerProvider';
import { ActionBar } from './ActionBar';
import { useTaskModal } from './contexts/TaskModalContext';
import SubscriptionBadge from './SubscriptionBadge';
import { Modal, Button } from 'antd';
import logo_no_text from '../public/images/logo-no-text.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faCalendarAlt,
    faChartBar,
    faChartLine,
    faCalendar,
    faRecycle,
    faCog,
    faCalendarWeek,
    faRepeat,
} from '@fortawesome/free-solid-svg-icons';
import { AddCircleOutline } from '@mui/icons-material';

const TopNavBar = () => {
    const isMobile = Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios';
    const [isRescheduling] = useAtom(isReschedulingAtom);
    const location = useLocation();
    const { customer, isLoading: isLoadingCustomer } = useCustomer();
    const { openTaskModal } = useTaskModal();

    // Return null if no customer or loading
    if (!customer || isLoadingCustomer) {
        return null;
    }

    // Return null for mobile devices
    if (isMobile) {
        return null;
    }

    const subscriptionStatus = customer.stripeStatus === 'active' ? 'Premium User' : 'Free Tier';

    const getPageTitleConfig = () => {
        const path = location.pathname.split('/')[1] || 'home';
        const configs = {
            home: { title: 'Home', icon: faHome, color: 'text-blue-800' },
            organizer: { title: 'Organizer', icon: faCalendar, color: 'text-blue-800' },
            stats: { title: 'Stats', icon: faChartLine, color: 'text-blue-800' },
            calendar: { title: 'Calendar', icon: faCalendar, color: 'text-blue-800' },
            habits: { title: 'Habits', icon: faRecycle, color: 'text-blue-800' },
            settings: { title: 'Settings', icon: faCog, color: 'text-blue-800' },
            priorities: { title: 'Priorities', icon: faChartBar, color: 'text-blue-800' },
            'upcoming-events': { title: 'Upcoming Events', icon: faCalendarWeek, color: 'text-blue-800' },
        };
        return configs[path.toLowerCase()] || configs.home;
    };

    return (
        <div className="flex sticky top-0 z-50 justify-between items-center p-4 shadow-sm bg-appBackground">
            {/* Left section */}
            <div className="flex-1">
                <h1 className="flex items-center text-4xl font-bold">
                    <FontAwesomeIcon icon={getPageTitleConfig().icon} className="mr-4 text-blue-500" />
                    <span className={getPageTitleConfig().color}>{getPageTitleConfig().title}</span>
                </h1>
            </div>

            {/* Center section - with adjusted flex proportions */}
            <div className="flex flex-[2] items-center">
                <div className="flex-[0.5] flex justify-start">
                    {isRescheduling && (
                        <div className="flex gap-2 items-center px-4 py-2 whitespace-nowrap bg-green-100 rounded-full">
                            <div className="w-2 h-2 bg-green-600 rounded-full animate-pulse"></div>
                            <span className="font-semibold text-green-800">Rescheduling tasks...</span>
                        </div>
                    )}
                </div>
                <div className="flex-[2] flex justify-center">
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            openTaskModal(null);
                        }}
                        className="px-6 py-2 text-white whitespace-nowrap bg-green-500 rounded-full shadow-md transition-colors duration-300 hover:bg-green-600"
                        starticon={<AddCircleOutline />}
                        variant="contained"
                    >
                        <AddCircleOutline />
                        Add New Task / Habit
                    </Button>
                </div>
                <div className="flex-[0.5]"></div>
            </div>

            {/* Right section */}
            <div className="flex flex-1 justify-end items-center">
                <div className="mr-4">
                    <SubscriptionBadge status={subscriptionStatus} />
                </div>
                <ActionBar />
            </div>
        </div>
    );
};

export default TopNavBar;
