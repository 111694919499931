import { useCallback } from 'react';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { tokenAtom } from '../../../atoms/tokenAtoms';
import { useCustomer } from '../../../providers/CustomerProvider';
import { useQuery, useQueryClient } from 'react-query';

const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';

export const useTaskSchedule = () => {
    const token = useAtomValue(tokenAtom);
    const { customer } = useCustomer();
    const queryClient = useQueryClient();

    const fetchTaskSchedule = useCallback(async () => {
        if (!token || !customer) throw new Error('Token or customer not available');

        const response = await axios.get(baseUrl + 'api/tasks/calculateTaskSchedule', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== 200) {
            throw new Error('Failed to fetch task schedule: ' + response.statusText);
        }

        console.log('Fetched task schedule:', response.data);
        return response.data;
    }, [token, customer]);

    const {
        data: taskSchedule,
        isLoading,
        error,
    } = useQuery(['taskSchedule', customer?.id], fetchTaskSchedule, {
        enabled: !!token && !!customer,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 15 * 60 * 1000, // 15 minutes
        retry: 3,
        retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
        onError: (error) => {
            console.error('Error fetching task schedule:', error);
        },
    });

    const invalidateTaskSchedule = useCallback(() => {
        queryClient.invalidateQueries(['taskSchedule', customer?.id]);
    }, [queryClient, customer?.id]);

    return {
        taskSchedule,
        opportunityNowDate: taskSchedule?.opportunityNowDate,
        overTheHorizonDate: taskSchedule?.overTheHorizonDate,
        parkingLotDate: taskSchedule?.parkingLotDate,
        isLoading,
        error,
        refetch: invalidateTaskSchedule,
    };
};

export default useTaskSchedule;
