import React, { createContext, useContext, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useCustomer } from '../providers/CustomerProvider';
import { useGoogleCalendarEvents } from '../components/tasks/hooks/useGoogleCalendarEvents';
import { useMicrosoftCalendarEvents } from '../components/tasks/hooks/useMicrosoftCalendarEvents';
import { useAtom } from 'jotai';
import { tokenAtom } from '../atoms/tokenAtoms';

const CalendarEventsContext = createContext();

export const useCalendarEvents = () => {
    const context = useContext(CalendarEventsContext);
    if (!context) {
        throw new Error('useCalendarEvents must be used within a CalendarEventsProvider');
    }
    return context;
};

export const CalendarEventsProvider = ({ children }) => {
    const { customer, isLoading: isCustomerLoading, refetchCustomer } = useCustomer();
    const [token] = useAtom(tokenAtom);
    const queryClient = useQueryClient();

    const { fetchGoogleCalendars, fetchGoogleEvents } = useGoogleCalendarEvents(customer);
    const { fetchMicrosoftCalendars, fetchMicrosoftEvents } = useMicrosoftCalendarEvents(customer);

    const refreshTokensAndUpdateCustomer = async () => {
        try {
            const response = await fetch(`${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/refreshTokens`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to refresh tokens');
            }

            const updatedCustomer = await response.json();
            // Update the customer data in the CustomerProvider
            await refetchCustomer();
            return updatedCustomer;
        } catch (error) {
            console.error('Error refreshing tokens:', error);
            throw error;
        }
    };

    const fetchCalendarListForAllAccounts = useCallback(
        async (currentCustomer) => {
            if (!currentCustomer || !currentCustomer.accounts || currentCustomer.accounts.length === 0) {
                return [];
            }

            const newCalendarList = [];
            const accountsNeedingRefresh = [];

            for (const account of currentCustomer.accounts) {
                if (account.error || account.dirty) continue;

                try {
                    if (account.type === 'google') {
                        const accountCalendars = await fetchGoogleCalendars(account.accessToken);
                        if (accountCalendars?.items) {
                            accountCalendars.items.forEach((calendar) => {
                                calendar.account = account;
                                newCalendarList.push(calendar);
                            });
                        }
                    } else if (account.type === 'microsoft') {
                        const accountCalendars = await fetchMicrosoftCalendars(account.accessToken);
                        accountCalendars.forEach((calendar) => {
                            calendar.account = account;
                            newCalendarList.push(calendar);
                        });
                    }
                } catch (error) {
                    if (error.response?.status === 401) {
                        console.log(`Account ${account.id} needs token refresh`);
                        accountsNeedingRefresh.push(account);
                        continue;
                    }
                    console.error(`Error fetching calendars for account ${account.id}:`, error);
                }
            }

            if (accountsNeedingRefresh.length > 0) {
                console.log('Some accounts need token refresh, refreshing tokens...');
                const updatedCustomer = await refreshTokensAndUpdateCustomer();
                return fetchCalendarListForAllAccounts(updatedCustomer); // Retry with updated customer
            }

            return newCalendarList;
        },
        [fetchGoogleCalendars, fetchMicrosoftCalendars]
    );

    const fetchCalendarEvents = useCallback(async () => {
        if (!customer || isCustomerLoading) {
            console.log('Customer not loaded or loading');
            return [];
        }

        try {
            const calendarList = await fetchCalendarListForAllAccounts(customer);
            const googleEvents = await fetchGoogleEvents(calendarList);
            const microsoftEvents = await fetchMicrosoftEvents(calendarList);

            const allEvents = [...googleEvents, ...microsoftEvents].map((event) => ({
                ...event,
                isMultiDay: new Date(event.start).toDateString() !== new Date(event.end).toDateString(),
                multiDayEventEndDate: event.end,
                end:
                    new Date(event.start).toDateString() !== new Date(event.end).toDateString()
                        ? event.start
                        : event.end,
            }));

            return allEvents;
        } catch (error) {
            console.error('Error fetching calendar events:', error);
            throw error;
        }
    }, [customer, isCustomerLoading, fetchCalendarListForAllAccounts, fetchGoogleEvents, fetchMicrosoftEvents]);

    const {
        data: calendarEvents,
        isLoading,
        error,
    } = useQuery('calendarEvents', fetchCalendarEvents, {
        enabled: !!customer && !isCustomerLoading,
        retry: 2,
    });

    const makeCalendarPrimary = useCallback(
        async (accountId) => {
            console.log('Making ' + accountId + ' primary account');
            try {
                const response = await fetch(
                    `${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/makeAccountPrimary`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ accountId: accountId }),
                    }
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                await refetchCustomer();
                queryClient.invalidateQueries('calendarEvents');
            } catch (error) {
                console.error('Error making calendar primary:', error);
            }
        },
        [token, refetchCustomer, queryClient]
    );

    const deleteAccount = useCallback(
        async (email) => {
            console.log('Deleting account ' + email);
            try {
                const response = await fetch(`${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/deleteAccount`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                await refetchCustomer();
                queryClient.invalidateQueries('calendarEvents');
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        },
        [token, refetchCustomer, queryClient]
    );

    const contextValue = useMemo(
        () => ({
            calendarEvents,
            isLoading,
            error,
            refetchCalendarEvents: () => queryClient.invalidateQueries('calendarEvents'),
            makeCalendarPrimary,
            deleteAccount,
        }),
        [calendarEvents, isLoading, error, queryClient, makeCalendarPrimary, deleteAccount]
    );

    return <CalendarEventsContext.Provider value={contextValue}>{children}</CalendarEventsContext.Provider>;
};
