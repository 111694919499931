import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, ListSubheader } from '@mui/material';

const CalendarSelector = ({ accounts, selectedCalendar, onCalendarSelect }) => {
    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="calendar-select">Select Calendar</InputLabel>
            <Select
                value={selectedCalendar || ''}
                onChange={(e) => onCalendarSelect(e.target.value)}
                label="Select Calendar"
                inputProps={{
                    name: 'calendar-select',
                    id: 'calendar-select',
                }}
            >
                <MenuItem value="">
                    <em>None (Auto-select)</em>
                </MenuItem>
                {accounts.map((account) => [
                    <ListSubheader key={account.email} component="div" style={{ color: '#2563EB', fontWeight: 'bold' }}>
                        {account.email}
                    </ListSubheader>,
                    ...account.calendars.map((calendar) => (
                        <MenuItem key={calendar.id} value={calendar.id} style={{ paddingLeft: '24px' }}>
                            {calendar.summary}
                        </MenuItem>
                    )),
                ])}
            </Select>
        </FormControl>
    );
};

export default CalendarSelector;
