import React from 'react';

const InlineLoading = ({ className = '', text = 'Loading...' }) => {
    return (
        <div className={`flex justify-center items-center p-4 ${className}`} role="status" aria-live="polite">
            <div className="inline-block w-16 h-16 rounded-full border-t-4 border-b-4 animate-spin"></div>
            <p className="ml-4 text-xl font-semibold text-blue-800">{text}</p>
            <span className="sr-only">Loading</span>
        </div>
    );
};

export default InlineLoading;
