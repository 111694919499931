import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
} from '@mui/material';
import { format, parseISO, isAfter } from 'date-fns';
import { motion } from 'framer-motion';
import EditIcon from '@mui/icons-material/Edit';
import { useTaskModal } from '../../contexts/TaskModalContext';
import {
    getDuration,
    getPriorityColor,
    formatRecurrenceRule,
    getNextOccurrence,
    formatNextOccurrence,
} from './HabitCard';
import { useTasks } from '../../providers/TasksProvider';
import { useCalendarEvents } from '../../contexts/CalendarEventsContext';

const MotionBox = motion(Box);
const MotionButton = motion(Button);

function HabitDetail() {
    const { id } = useParams();
    const { tasks } = useTasks();
    const { calendarEvents } = useCalendarEvents();
    const [task, setTask] = useState(null);
    const { openTaskModal } = useTaskModal();
    const navigate = useNavigate();

    useEffect(() => {
        if (tasks && calendarEvents && id) {
            const foundTask = tasks.find((t) => t.id === id);
            if (foundTask) {
                const now = new Date();
                const children = tasks.filter(
                    (childTask) => childTask.type === 'RecurringTask' && childTask.parentTask?.id === foundTask.id
                );
                const taskEvents = calendarEvents.filter(
                    (event) => event.title === foundTask.title || children.some((child) => child.title === event.title)
                );
                const futureEvents = taskEvents.filter((event) => isAfter(parseISO(event.start), now));

                setTask({
                    ...foundTask,
                    children,
                    isScheduled: futureEvents.length > 0,
                    futureEvents,
                });
            }
        }
    }, [tasks, calendarEvents, id]);

    const handleEditClick = useCallback(() => {
        if (task) {
            openTaskModal(task);
        }
    }, [task, openTaskModal]);

    const handleEventClick = useCallback(
        (event) => {
            const eventDate = parseISO(event.start);
            navigate(`/calendar?view=timeGridDay&date=${format(eventDate, 'yyyy-MM-dd')}`);
        },
        [navigate]
    );

    const handleKeyDown = useCallback((event, callback) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            callback(event);
        }
    }, []);

    if (!task) {
        return <Typography>Loading...</Typography>;
    }

    const nextOccurrence = getNextOccurrence(task);

    return (
        <Box sx={{ maxWidth: 600, margin: 'auto', marginTop: 4 }}>
            <MotionButton
                variant="text"
                onClick={() => navigate('/habits')}
                sx={{
                    marginBottom: 2,
                    color: 'primary.main',
                }}
                aria-label="Back to Habits"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                ← Back to Habits
            </MotionButton>
            <Card
                sx={{
                    borderRadius: 2,
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    borderLeft: task ? `8px solid ${getPriorityColor(task.priority)}` : 'none',
                    position: 'relative',
                }}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 1,
                    }}
                    onClick={handleEditClick}
                >
                    <EditIcon />
                </IconButton>
                <CardContent>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {task.title}
                    </Typography>

                    <Box display="flex" flexDirection="column" gap={2} mb={3}>
                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" display="inline-block" width="120px">
                                Duration:
                            </Box>
                            {getDuration(task)}
                        </Typography>
                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" display="inline-block" width="120px">
                                Repeat:
                            </Box>
                            {formatRecurrenceRule(task.recurrenceRule)}
                        </Typography>

                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" display="inline-block" width="120px">
                                Status:
                            </Box>
                            {task.isScheduled ? 'Scheduled' : 'Not Scheduled'}
                        </Typography>
                    </Box>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Upcoming Calendar Events
                    </Typography>
                    <List aria-label="Upcoming events">
                        {task.futureEvents && task.futureEvents.length > 0 ? (
                            task.futureEvents.slice(0, 5).map((event, index) => (
                                <React.Fragment key={event.id}>
                                    {index > 0 && <Divider />}
                                    <MotionBox
                                        component={ListItem}
                                        button
                                        onClick={() => handleEventClick(event)}
                                        onKeyDown={(e) => handleKeyDown(e, () => handleEventClick(event))}
                                        sx={{
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            },
                                        }}
                                        role="button"
                                        tabIndex={0}
                                        aria-label={`Event on ${format(parseISO(event.start), 'EEEE, MMMM d, yyyy')}`}
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        <ListItemText
                                            primary={format(parseISO(event.start), 'EEEE, MMMM d, yyyy')}
                                            secondary={`${format(parseISO(event.start), 'h:mm a')} - ${format(parseISO(event.end), 'h:mm a')}`}
                                        />
                                    </MotionBox>
                                </React.Fragment>
                            ))
                        ) : (
                            <ListItem>
                                <ListItemText primary="No upcoming events" />
                            </ListItem>
                        )}
                    </List>
                </CardContent>
            </Card>
        </Box>
    );
}

export default HabitDetail;
