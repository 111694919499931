import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CollectionItem from './CollectionItem';

const CollectionsPane = ({
    sortedProjects,
    selectedCollection,
    handleCollectionClick,
    handleTaskMoved,
    collectionIcon,
    collectionColor,
}) => {
    return (
        <div className="p-4">
            <div className="mb-4">
                <h2 className="text-lg">COLLECTIONS</h2>
                <ul>
                    {sortedProjects.map((collection) => (
                        <CollectionItem
                            key={collection.id}
                            collection={collection}
                            selectedCollection={selectedCollection}
                            handleCollectionClick={handleCollectionClick}
                            icon={collectionIcon[collection.type]}
                            color={collectionColor[collection.type]}
                            customName={collection.customName}
                            onTaskMoved={handleTaskMoved}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CollectionsPane;
