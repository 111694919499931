import { useCallback } from 'react';
import axios from 'axios';
import { subDays, addDays, startOfDay, endOfDay, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../atoms/tokenAtoms';

const googleColorConstants = {
    undefined: '#039be5',
    1: '#7986cb',
    2: '#33b679',
    3: '#8e24aa',
    4: '#e67c73',
    5: '#f6c026',
    6: '#f5511d',
    7: '#039be5',
    8: '#616161',
    9: '#3f51b5',
    10: '#0b8043',
    11: '#d60000',
};

export const useGoogleCalendarEvents = (customer) => {
    const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';
    const [token] = useAtom(tokenAtom);

    const fetchGoogleCalendars = useCallback(async (token) => {
        try {
            const { data } = await axios.get('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Google calendar list:', data);
            return data;
        } catch (error) {
            if (error.response?.status === 401) {
                console.log('401 error fetching Google calendars - token needs refresh');
                throw error; // Propagate 401 errors up
            }
            console.error('Error fetching Google calendars:', error.response?.data || error.message);
            return null;
        }
    }, []);

    const fetchGoogleEvents = useCallback(
        async (currentCalendarList) => {
            console.log('Starting fetchGoogleEvents with calendar list:', currentCalendarList);
            const defaultTimeZone = customer.defaultTimeZone;
            const now = new Date();
            const minDate = startOfDay(subDays(now, 7));
            const maxDate = endOfDay(addDays(now, 90));

            console.log('Date range:', {
                defaultTimeZone,
                minDate: minDate.toISOString(),
                maxDate: maxDate.toISOString(),
            });

            const googleCalendars = currentCalendarList.filter((calendar) => {
                console.log('Filtering calendar:', {
                    id: calendar.id,
                    accountType: calendar.account?.type,
                    dirty: calendar.account?.dirty,
                    isHoliday: calendar.id.indexOf('#holiday@group.v.calendar.google.com') !== -1,
                });

                const account = customer.accounts.find((account) =>
                    account.calendars.some((accCal) => accCal.id === calendar.id)
                );
                const accountCalendar = account ? account.calendars.find((accCal) => accCal.id === calendar.id) : null;
                const isUsing = accountCalendar ? accountCalendar.using : calendar.using;

                console.log('Calendar details:', {
                    id: calendar.id,
                    hasAccount: !!account,
                    hasAccountCalendar: !!accountCalendar,
                    isUsing,
                });

                if (calendar.account.type !== 'google') {
                    return false;
                }
                if (calendar.account.dirty) {
                    return false;
                }
                if (calendar.id.indexOf('#holiday@group.v.calendar.google.com') !== -1) {
                    return false;
                }
                if (!isUsing) {
                    return false;
                }
                return true;
            });

            console.log('Filtered Google calendars:', googleCalendars);

            const allEvents = await Promise.all(
                googleCalendars.map(async (calendar) => {
                    console.log(`Fetching events for calendar: ${calendar.id}`);
                    try {
                        const response = await axios.get(
                            `https://www.googleapis.com/calendar/v3/calendars/${calendar.id}/events`,
                            {
                                params: {
                                    timeMin: formatInTimeZone(minDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
                                    timeMax: formatInTimeZone(maxDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
                                    maxResults: 1000,
                                    singleEvents: true,
                                    orderBy: 'startTime',
                                    fields:
                                        'items(id,summary,description,location,creator,organizer,start,end,' +
                                        'attendees,recurringEventId,recurrence,sequence,colorId,visibility,' +
                                        'iCalUID,attachments,conferenceData,extendedProperties,hangoutLink,' +
                                        'guestsCanModify,guestsCanInviteOthers,guestsCanSeeOtherGuests,' +
                                        'privateCopy,locked,source,status,created,updated,reminders,eventType,' +
                                        'transparency,htmlLink)',
                                },
                                headers: {
                                    Authorization: `Bearer ${calendar.account.accessToken}`,
                                    'Content-Type': 'application/json',
                                },
                            }
                        );
                        console.log(`Successfully fetched events for calendar ${calendar.id}:`, {
                            eventCount: response.data.items.length,
                        });

                        return response.data.items.map((event) => ({
                            id: event.id,
                            calendarId: calendar.id,
                            htmlLink: event.htmlLink,
                            title: event.summary,
                            start: event.start.dateTime || event.start.date,
                            end: event.end.dateTime || event.end.date,
                            allDay: !event.start.dateTime,
                            color: calendar.backgroundColor || googleColorConstants[event.colorId],
                            accessToken: calendar.account.accessToken,
                            status: event.transparency === 'transparent' ? 'free' : 'busy',
                            textColor: 'white',
                            serviceType: 'google',
                            // Additional useful fields
                            description: event.description,
                            location: event.location,
                            creator: event.creator,
                            organizer: event.organizer,
                            attendees: event.attendees,
                            recurringEventId: event.recurringEventId,
                            recurrence: event.recurrence,
                            sequence: event.sequence, // For tracking updates to recurring events
                            colorId: event.colorId,
                            visibility: event.visibility, // 'default', 'public', 'private', 'confidential'
                            iCalUID: event.iCalUID,
                            attachments: event.attachments,
                            conferenceData: event.conferenceData, // For Google Meet links
                            extendedProperties: event.extendedProperties,
                            hangoutLink: event.hangoutLink,
                            guestsCanModify: event.guestsCanModify,
                            guestsCanInviteOthers: event.guestsCanInviteOthers,
                            guestsCanSeeOtherGuests: event.guestsCanSeeOtherGuests,
                            privateCopy: event.privateCopy,
                            locked: event.locked,
                            source: event.source, // Where the event came from
                            status: event.status, // 'confirmed', 'tentative', 'cancelled'
                            created: event.created,
                            updated: event.updated,
                            reminders: event.reminders,
                            eventType: event.eventType, // 'default', 'outOfOffice', 'focusTime'
                        }));
                    } catch (error) {
                        console.error(`Error fetching events for calendar ${calendar.id}:`, {
                            status: error.response?.status,
                            data: error.response?.data,
                            message: error.message,
                        });
                        return [];
                    }
                })
            );

            const flattenedEvents = allEvents.flat();
            console.log('Events summary:', {
                totalCalendars: googleCalendars.length,
                totalEvents: flattenedEvents.length,
                eventsPerCalendar: allEvents.map((events) => events.length),
            });
            return flattenedEvents;
        },
        [customer, googleColorConstants]
    );

    const updateGoogleEvent = useCallback(async (calendarId, eventId, accessToken, updatedEvent) => {
        console.log(`Updating event in calendar ${calendarId}`);
        console.log('Updated event data:', updatedEvent);
        try {
            const response = await axios.patch(
                `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
                updatedEvent,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(`Successfully updated event: ${response.data.id}`);
            return response.data;
        } catch (error) {
            console.error('Error updating Google event:', error.response?.data || error.message);
            throw error;
        }
    }, []);

    const handleGoogleAuth = useCallback(
        async (authCode) => {
            console.log('Sending google auth code to ' + baseUrl + 'oauth/googleauth');

            try {
                const response = await fetch(baseUrl + 'oauth/googleauth', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: JSON.stringify(authCode),
                });

                console.log('Server responded with status', response.status);

                const responseData = await response.text();
                console.log('Server responded with body', responseData);

                let parsedData;
                try {
                    parsedData = JSON.parse(responseData);
                } catch (e) {
                    console.error('Error parsing JSON response:', e);
                    parsedData = null;
                }

                if (!response.ok) {
                    return {
                        ok: false,
                        status: response.status,
                        data: parsedData || { message: 'An unexpected error occurred' },
                    };
                }

                console.log('Google auth response:', parsedData);
                return {
                    ok: true,
                    status: response.status,
                    data: parsedData,
                };
            } catch (err) {
                console.error('Error during Google authentication:', err);
                return {
                    ok: false,
                    status: 500,
                    data: { message: 'An unexpected error occurred during authentication' },
                };
            }
        },
        [baseUrl, token]
    );

    return { fetchGoogleCalendars, fetchGoogleEvents, updateGoogleEvent, handleGoogleAuth };
};

export { googleColorConstants };
