import { Route, Routes, useLocation } from 'react-router-dom';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import HomeLoading from './components/HomeLoading';
import MagicLogin from './components/auth/MagicLogin';
import { isAuthenticatedAtom } from './atoms/authAtoms';
import { useAtom } from 'jotai';
import { UpcomingEvents } from './components/calendar/UpcomingEvents';
import HabitDetail from './components/habits/HabitDetail';
import SideNavBar from './components/SideNavBar'; // Import the new SideNavBar component
import TopNavBar from './TopNavBar';
import InlineLoading from './components/InlineLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTh } from '@fortawesome/free-solid-svg-icons';
import { useDeviceType } from './hooks/useDeviceType';
import Briefing from './components/home/Briefing';
import { useSettings } from './atoms/SettingsAtom';
const DRAWER_WIDTH = 240;
const preloadComponent = (factory) => {
    factory();
};

const lazyWithPreload = (factory) => {
    const Component = lazy(factory);
    Component.preload = () => preloadComponent(factory);
    return Component;
};

// Preloaded components
const RequireAuth = lazyWithPreload(() => import('./components/auth/RequireAuth'));
const Home = lazyWithPreload(() => import('./components/home/Home'));
const MynCalendar = lazyWithPreload(() => import('./components/calendar/MynCalendar'));
const Settings = lazyWithPreload(() => import('./components/settings/Settings'));
const Organizer = lazyWithPreload(() => import('./components/Organizer')); // Updated name

// Regular lazy-loaded components
const GettingStarted = lazy(() => import('./components/auth/GettingStarted'));
const MsCallback = lazy(() => import('./components/calendar/MsCallback'));
const ScheduleEditor = lazy(() => import('./components/settings/ScheduleEditor'));
const Version = lazy(() => import('./Version'));
const TimeManagementSurvey = lazy(() => import('./components/survey/TimeManagementSurvey'));
const Stats = lazy(() => import('./components/stats/Stats'));

const Habits = lazyWithPreload(() => import('./components/habits/Habits'));

// Add this new import
const PriorityPage = lazyWithPreload(() => import('./components/priorities/PriorityPage'));

const preloadPriorityComponents = () => {
    RequireAuth.preload();
    Home.preload();
    MynCalendar.preload();
    Settings.preload();
    Organizer.preload();
};

function AppRoutes({ openTaskModal }) {
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const exchangeToken = searchParams.get('token');
    const directToken = searchParams.get('directtoken');
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const { isMobile, smallCalendar } = useDeviceType();
    const { settings } = useSettings();
    const [isHovered, setIsHovered] = useState(false);

    const isDrawerOpen = settings.sidebarPinned || isHovered;
    const COLLAPSED_WIDTH = 60;

    useEffect(() => {
        // Preload priority components in the background
        preloadPriorityComponents();
    }, []);

    const handleHoverChange = (hovered) => {
        setIsHovered(hovered);
    };

    if (!isAuthenticated && !exchangeToken && !directToken) {
        return (
            <Suspense fallback={<HomeLoading />}>
                <Routes>
                    <Route path="*" element={<GettingStarted />} />
                </Routes>
            </Suspense>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                backgroundColor: '#eff6ff',
            }}
        >
            <SideNavBar onHoverChange={handleHoverChange} />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: {
                        xs: '100%',
                        md: `calc(100% - ${isDrawerOpen ? DRAWER_WIDTH : COLLAPSED_WIDTH}px)`,
                    },
                    marginLeft: {
                        xs: 0,
                        md: `${isDrawerOpen ? DRAWER_WIDTH : COLLAPSED_WIDTH}px`,
                    },
                    transition: (theme) =>
                        theme.transitions.create(['width', 'margin'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                }}
            >
                {!isMobile && <TopNavBar />}
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: (theme) => theme.palette.appBackground,
                        minHeight: '100vh',
                    }}
                >
                    <Suspense fallback={<HomeLoading />}>
                        <Routes>
                            <Route path="/gettingstarted" element={<GettingStarted />} />
                            <Route path="/magic-login" element={<MagicLogin />} />

                            <Route element={<RequireAuth redirectPath="/GettingStarted" />}>
                                <Route path="/" element={isMobile ? <Briefing isPlanner={false} /> : <MynCalendar />} />
                                <Route path="home" element={isMobile ? <Briefing isPlanner={false} /> : <Home />} />
                                <Route path="organizer" element={<Organizer />} />
                                <Route path="mscallback" element={<MsCallback />} />
                                <Route path="stats" element={<Stats />} />
                                <Route path="settings" element={<Settings success="false" />} />
                                <Route path="cancel" element={<Settings success="false" />} />
                                <Route path="success" element={<Settings success="true" />} />
                                <Route
                                    path="calendar"
                                    element={<MynCalendar defaultView={isMobile ? 'listMonth' : 'dayGridMonth'} />}
                                />
                                <Route path="habits" element={<Habits />} />
                                <Route path="version" element={<Version />} />
                                <Route path="time-mangement-survey" element={<TimeManagementSurvey />} />
                                <Route path="/settings/:tab?" element={<Settings />} />
                                <Route path="upcoming-events" element={<UpcomingEvents />} />
                                <Route path="/habits/:id" element={<HabitDetail />} />
                                <Route path="priorities" element={<PriorityPage />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </Box>
            </Box>
        </Box>
    );
}

export default AppRoutes;
