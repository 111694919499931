import React, { useState } from 'react';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Image,
    BrokenImage,
    Autorenew,
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Schedule as ScheduleIcon,
    Cancel as CancelIcon,
    CenterFocusStrong,
    FastForward,
} from '@mui/icons-material';
import { IconButton, Paper, CircularProgress, Tooltip } from '@mui/material';
import { useSettings } from './atoms/SettingsAtom';
import { usePlanning } from './hooks/usePlanning';
import { useAtom } from 'jotai';
import { isFocusModeAtom } from './atoms/isFocusModeAtom';
import { isReschedulingAtom } from './atoms/isReschedulingAtom';
import KickTheCanDialog from './components/KickTheCanDialog';

const ActionButton = ({ tooltip, onClick, disabled, children }) => (
    <Tooltip title={tooltip} aria-label={tooltip}>
        <span>
            <IconButton onClick={onClick} disabled={disabled}>
                {children}
            </IconButton>
        </span>
    </Tooltip>
);

export const ActionBar = () => {
    const { settings, toggleSetting } = useSettings();
    const { instantPlan, kickTheCan, scheduleAll, unScheduleAll } = usePlanning();
    const [isRescheduling] = useAtom(isReschedulingAtom);
    const [isFocusMode, setIsFocusMode] = useAtom(isFocusModeAtom);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleKickTheCan = () => {
        kickTheCan();
        setIsDialogOpen(false);
    };

    const actionButtons = [
        {
            tooltip: 'Toggle focus mode',
            onClick: () => setIsFocusMode(!isFocusMode),
            icon: <CenterFocusStrong className={isFocusMode ? 'text-green-500' : ''} />,
            className: isFocusMode ? 'bg-green-100' : '',
        },
        {
            tooltip: 'Toggle background',
            onClick: () => toggleSetting('showBackground'),
            icon: settings.showBackground ? (
                <Image style={{ fontSize: '2rem' }} />
            ) : (
                <BrokenImage style={{ fontSize: '2rem' }} />
            ),
        },
        {
            tooltip: 'Toggle future tasks visibility',
            onClick: () => toggleSetting('showFuture'),
            icon: settings.showFuture ? <VisibilityIcon /> : <VisibilityOffIcon />,
        },
        {
            tooltip: 'Toggle completed tasks visibility',
            onClick: () => toggleSetting('showCompleted'),
            icon: settings.showCompleted ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />,
        },
        {
            tooltip: 'Fetch new plan',
            onClick: instantPlan,
            icon: isRescheduling ? (
                <CircularProgress size={27} style={{ color: 'green' }} />
            ) : (
                <Autorenew style={{ fontSize: '2rem' }} />
            ),
        },
        {
            tooltip: 'Schedule all tasks',
            onClick: scheduleAll,
            icon: <ScheduleIcon style={{ fontSize: '2rem' }} />,
        },
        {
            tooltip: 'Unschedule all tasks',
            onClick: unScheduleAll,
            icon: <CancelIcon style={{ fontSize: '2rem' }} />,
        },
    ];

    return (
        <Paper elevation={3} className="flex items-center p-2 bg-white rounded-lg">
            <div className="flex items-center space-x-4">
                <ActionButton tooltip="Kick all tasks into the future" onClick={() => setIsDialogOpen(true)}>
                    <FastForward style={{ fontSize: '2rem' }} />
                </ActionButton>

                {actionButtons.map((button, index) => (
                    <ActionButton
                        key={button.tooltip}
                        tooltip={button.tooltip}
                        onClick={button.onClick}
                        className={button.className}
                    >
                        {button.icon}
                    </ActionButton>
                ))}
            </div>

            <KickTheCanDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onConfirm={handleKickTheCan}
            />
        </Paper>
    );
};
