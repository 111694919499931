import React, { useState, useMemo, useCallback, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    useTheme,
    useMediaQuery,
    AppBar,
    Toolbar,
    Box,
    Divider,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faFolder,
    faRecycle,
    faChartBar,
    faChartLine,
    faQuestionCircle,
    faCog,
    faCalendar,
    faThumbtack,
    faCalendarWeek,
} from '@fortawesome/free-solid-svg-icons';
import { useSettings } from '../atoms/SettingsAtom';
import { Modal } from 'antd';
import { useCustomer } from '../providers/CustomerProvider';

const DRAWER_WIDTH = 240;
const COLLAPSED_WIDTH = 56;

const Logo = memo(() => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src="/images/logo-no-text.png" alt="Logo" style={{ width: 32, height: 32, marginRight: 8 }} />
        <Link to="/home" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ color: '#00AEEF' }}>mind</span>
                <span style={{ color: '#80D7F7' }}>your</span>
                <span style={{ color: '#FFC60B' }}>now</span>
            </Box>
        </Link>
    </Box>
));

const MenuItem = memo(({ item, isMobile, setMobileOpen, isActive }) => (
    <ListItem
        key={item.path}
        component={Link}
        to={item.path}
        selected={isActive(item.path)}
        onClick={(e) => {
            if (isMobile) setMobileOpen(false);
            item.onClick?.(e);
        }}
        className={`nav-item ${isActive(item.path) ? 'active-nav-item' : ''}`}
        sx={{
            '&.active-nav-item': {
                backgroundColor: 'rgba(96, 165, 250, 0.1)',
                borderLeft: '4px solid #60A5FA',
            },
            '&.Mui-selected': {
                backgroundColor: 'rgba(96, 165, 250, 0.1) !important',
                '&:hover': {
                    backgroundColor: 'rgba(96, 165, 250, 0.2) !important',
                },
            },
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
        }}
    >
        <ListItemIcon>
            <FontAwesomeIcon icon={item.icon} style={{ color: isActive(item.path) ? '#60A5FA' : '#9CA3AF' }} />
        </ListItemIcon>
        <ListItemText
            primary={item.label}
            sx={{
                color: isActive(item.path) ? '#60A5FA' : 'inherit',
            }}
        />
    </ListItem>
));

const DrawerContent = memo(
    ({ menuItems, bottomMenuItems, isMobile, settings, updateSetting, setMobileOpen, isActive }) => (
        <>
            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Logo />
                {!isMobile && (
                    <IconButton onClick={() => updateSetting('sidebarPinned', !settings.sidebarPinned)}>
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            style={{ color: settings.sidebarPinned ? '#60A5FA' : '#9CA3AF' }}
                        />
                    </IconButton>
                )}
            </Box>
            <Divider />
            <List>
                {menuItems.map((item) => (
                    <MenuItem key={item.path} item={item} isMobile={isMobile} isActive={isActive} />
                ))}
            </List>
            <Box sx={{ flexGrow: 1 }} />
            <Divider />
            <List>
                {bottomMenuItems.map((item) => (
                    <MenuItem key={item.path} item={item} isMobile={isMobile} isActive={isActive} />
                ))}
            </List>
        </>
    )
);

const SideNavBar = ({ onHoverChange }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { customer, isLoading: isLoadingCustomer } = useCustomer();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const { settings, updateSetting } = useSettings();
    const [isHovered, setIsHovered] = useState(false);

    // Return null if no customer or loading
    if (!customer || isLoadingCustomer) {
        return null;
    }

    const menuItems = useMemo(
        () => [
            { path: '/home', icon: faHome, label: 'Home' },
            { path: '/calendar', icon: faCalendar, label: 'Calendar' },
            { path: '/upcoming-events', icon: faCalendarWeek, label: 'Upcoming Events' },
            { path: '/organizer', icon: faFolder, label: 'Organizer' },
            { path: '/habits', icon: faRecycle, label: 'Habits' },
            { path: '/priorities', icon: faChartBar, label: 'Priorities' },
            { path: '/stats', icon: faChartLine, label: 'Stats' },
        ],
        []
    );

    const bottomMenuItems = useMemo(
        () => [
            {
                path: '/help',
                icon: faQuestionCircle,
                label: 'Help & Feedback',
                onClick: () => setIsModalOpen(true),
            },
            { path: '/settings', icon: faCog, label: 'Settings' },
        ],
        [setIsModalOpen]
    );

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const isActive = useCallback(
        (path) => {
            const currentPath = location.pathname;

            if (path === '/home' && (currentPath === '/' || currentPath === '/home')) {
                return true;
            }

            if (path === '/settings' && currentPath.startsWith('/settings')) {
                return true;
            }

            if (path === '/upcoming-events') {
                return currentPath === '/upcoming-events';
            }

            return currentPath === path;
        },
        [location.pathname]
    );

    const handleMouseEnter = () => {
        if (!settings.sidebarPinned) {
            setIsHovered(true);
            onHoverChange(true);
        }
    };

    const handleMouseLeave = () => {
        if (!settings.sidebarPinned) {
            setIsHovered(false);
            onHoverChange(false);
        }
    };

    return (
        <>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: DRAWER_WIDTH,
                        backgroundColor: '#dbeafe',
                        transition: 'width 0.3s ease-in-out',
                    },
                    '& .MuiBox-root': {
                        backgroundColor: '#dbeafe',
                    },
                }}
            >
                <DrawerContent
                    menuItems={menuItems}
                    bottomMenuItems={bottomMenuItems}
                    isMobile={true}
                    settings={settings}
                    updateSetting={updateSetting}
                    setMobileOpen={setMobileOpen}
                    isActive={isActive}
                />
            </Drawer>
            <Drawer
                variant="permanent"
                open={settings.sidebarPinned || isHovered}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: settings.sidebarPinned || isHovered ? DRAWER_WIDTH : COLLAPSED_WIDTH,
                        backgroundColor: '#1a237e',
                        color: '#ffffff',
                        transition: (theme) =>
                            theme.transitions.create(['width'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen,
                            }),
                        overflowX: 'hidden',
                        whiteSpace: 'nowrap',
                        '& .MuiListItemIcon-root': {
                            color: '#ffffff',
                            minWidth: 40,
                        },
                        '& .MuiListItemText-primary': {
                            color: '#ffffff',
                            fontSize: '1rem',
                            fontWeight: 500,
                            opacity: settings.sidebarPinned || isHovered ? 1 : 0,
                            transition: 'opacity 0.2s',
                        },
                        '& .MuiListItem-root:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        },
                        '& .Mui-selected': {
                            backgroundColor: 'rgba(255, 255, 255, 0.16)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            },
                        },
                    },
                    '& .MuiBox-root': {
                        backgroundColor: '#1a237e',
                        color: '#ffffff',
                        '& .logo-text': {
                            opacity: settings.sidebarPinned || isHovered ? 1 : 0,
                            transition: 'opacity 0.2s',
                            visibility: settings.sidebarPinned || isHovered ? 'visible' : 'hidden',
                        },
                    },
                }}
            >
                <DrawerContent
                    menuItems={menuItems}
                    bottomMenuItems={bottomMenuItems}
                    isMobile={false}
                    settings={settings}
                    updateSetting={updateSetting}
                    setMobileOpen={setMobileOpen}
                    isActive={isActive}
                />
            </Drawer>
            <Modal
                title="Mind Your Now - Early Access"
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
            >
                <p className="text-lg">
                    Mind Your Now is still in early access. For any issues or feedback, please email us at{' '}
                    <a href="mailto:support@mindyournow.com" className="text-blue-500 hover:underline">
                        support@mindyournow.com
                    </a>
                </p>
            </Modal>
        </>
    );
};

export default SideNavBar;
