import React, { useCallback, useMemo } from 'react';
import { TextField, MenuItem, Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collectionIcon, collectionColor } from '../Organizer';
import Box from '@mui/material/Box';
import { useProjects } from '../utils/projectApi';

const getProjectName = (project) => {
    if (project.customName) {
        return project.customName;
    }
    return project.type.charAt(0).toUpperCase() + project.type.slice(1).toLowerCase();
};

const ProjectIcon = React.memo(({ project }) => {
    const color = collectionColor[project.type] || 'text-gray-500';
    if (project.customEmoji) {
        return project.customEmoji;
    }
    return (
        <div
            style={{
                width: '24px',
                height: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <FontAwesomeIcon
                className={`text-xl cursor-pointer pointer-events-auto ${color}`}
                data-bs-toggle="tooltip"
                title={getProjectName(project)}
                icon={collectionIcon[project.type]}
            />
        </div>
    );
});

const TaskProjectSelector = ({ newProjectId, handleChangeProject }) => {
    const { projectsData, isLoading } = useProjects();

    const memoizedProjectsData = useMemo(() => projectsData, [projectsData]);

    const memoizedHandleChangeProject = useCallback(
        (value) => {
            handleChangeProject(value);
        },
        [handleChangeProject]
    );

    if (isLoading) {
        return <Skeleton variant="rectangular" width={200} height={40} />;
    }

    return (
        <Box width={200}>
            <TextField
                size="medium"
                label="Collection"
                select
                value={memoizedProjectsData.some((project) => project.id === newProjectId) ? newProjectId : ''}
                onChange={(e) => memoizedHandleChangeProject(e.target.value)}
                style={{ width: '200px', minWidth: '200px', flex: '0 0 auto' }}
            >
                {memoizedProjectsData.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                        <div className="flex items-center">
                            <ProjectIcon project={project} />
                            <span className="self-center ml-2">{getProjectName(project)}</span>
                        </div>
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export default React.memo(TaskProjectSelector);
