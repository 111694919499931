import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowPathIcon, ClockIcon, CalendarIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { usePlanning } from '../hooks/usePlanning';
import { Tooltip, Checkbox, FormControlLabel } from '@mui/material';

const KickTheCanDialog = ({ isOpen, onClose }) => {
    const { kickTheCan, isKickingTheCan } = usePlanning();
    const [rebalance, setRebalance] = useState(false);

    const handleConfirm = async () => {
        await kickTheCan(rebalance);
        onClose();
    };

    return (
        <Transition show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="overflow-y-auto fixed inset-0 z-10">
                    <div className="flex justify-center items-end p-4 min-h-full text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="overflow-hidden relative text-left bg-white rounded-lg shadow-xl transition-all transform sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="flex flex-shrink-0 justify-center items-center mx-auto w-12 h-12 bg-yellow-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon
                                                className="w-6 h-6 text-yellow-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-base font-semibold leading-6 text-gray-900"
                                            >
                                                Confirm "Kick the Can"
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Please review the following summary before proceeding:
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 sm:px-6">
                                    <div className="space-y-2">
                                        <div className="flex items-center text-sm text-gray-600">
                                            <ArrowPathIcon className="mr-2 w-5 h-5 text-blue-500" />
                                            Reschedule non-recurring tasks based on priority
                                        </div>
                                        <div className="flex items-center text-sm text-gray-600">
                                            <ClockIcon className="mr-2 w-5 h-5 text-green-500" />
                                            Leave recurring and auto-scheduled tasks unchanged
                                        </div>
                                        <div className="flex items-center text-sm text-gray-600">
                                            <CalendarIcon className="mr-2 w-5 h-5 text-red-500" />
                                            Clear your immediate schedule of non-recurring tasks
                                        </div>
                                    </div>
                                    <div className="flex items-center text-sm text-gray-600">
                                        <Tooltip title="Rebalance will reschedule all uncompleted tasks, including those with future start dates. This can help redistribute your workload more evenly across your schedule.">
                                            <div>
                                                <br />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={rebalance}
                                                            onChange={(e) => setRebalance(e.target.checked)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Rebalance all tasks"
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-3 py-2 w-full text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                        onClick={handleConfirm}
                                        disabled={isKickingTheCan}
                                    >
                                        {isKickingTheCan ? 'Processing...' : 'Confirm'}
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-3 py-2 mt-3 w-full text-sm font-semibold text-gray-900 bg-white rounded-md ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={onClose}
                                        disabled={isKickingTheCan}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default KickTheCanDialog;
