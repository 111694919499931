const MicrosoftCalendarIcon = ({ size = 512, className = '' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M242.526 36H36v206.526h206.526V36z" fill="#F25022" />
            <path d="M476 36H269.474v206.526H476V36z" fill="#7FBA00" />
            <path d="M242.526 269.474H36V476h206.526V269.474z" fill="#00A4EF" />
            <path d="M476 269.474H269.474V476H476V269.474z" fill="#FFB900" />
        </svg>
    );
};

export default MicrosoftCalendarIcon;
