import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useTasks } from '../providers/TasksProvider';
import { useTaskFilters } from './tasks/hooks/useTaskFilters';

export const Overview = ({ handleFilterClick, selectedFilter }) => {
    const { tasks } = useTasks();
    const {
        tasksAccounted,
        tasksAccountedFiltered,
        tasksPastDue,
        tasksDueTodayOrPast,
        tasksCriticalFiltered,
        tasksOpportunityNowFiltered,
        tasksOverTheHorizonFiltered,
        tasksParkingLotFiltered,
        tasksSchedulingIssues,
    } = useTaskFilters(tasks);

    const getTextColor = (filter) => {
        const colors = {
            TOTAL_TASKS: 'text-gray-500',
            TODAYS_TASKS: 'text-green-700',
            PAST_DUE: 'text-red-500',
            DUE_TODAY: 'text-yellow-600',
            CRITICAL: 'text-myncritical-400',
            OPPORTUNITY_NOW: 'text-mynopportunitynow-400',
            OVER_THE_HORIZON: 'text-mynoverthehorizon-400',
            PARKING_LOT: 'text-mynparkinglot-400',
            SCHEDULING_ISSUES: 'text-red-400',
        };

        return colors[filter];
    };

    const getFilterName = (filter) => {
        const names = {
            TOTAL_TASKS: 'All Tasks',
            TODAYS_TASKS: "Today's Tasks",
            PAST_DUE: 'Past Due',
            DUE_TODAY: 'Due Today',
            CRITICAL: 'Critical',
            OPPORTUNITY_NOW: 'Opportunity Now',
            OVER_THE_HORIZON: 'Over the Horizon',
            PARKING_LOT: 'Parking Lot',
            SCHEDULING_ISSUES: 'Scheduling Issues',
        };

        return names[filter];
    };

    const getFilterCount = useMemo(() => {
        const counts = {
            TOTAL_TASKS: tasksAccounted.length,
            TODAYS_TASKS: tasksAccountedFiltered.length,
            PAST_DUE: tasksPastDue.length,
            DUE_TODAY: tasksDueTodayOrPast.length,
            CRITICAL: tasksCriticalFiltered.length,
            OPPORTUNITY_NOW: tasksOpportunityNowFiltered.length,
            OVER_THE_HORIZON: tasksOverTheHorizonFiltered.length,
            PARKING_LOT: tasksParkingLotFiltered.length,
            SCHEDULING_ISSUES: tasksSchedulingIssues.length,
        };

        return (filter) => counts[filter];
    }, [
        tasksAccounted,
        tasksAccountedFiltered,
        tasksPastDue,
        tasksDueTodayOrPast,
        tasksCriticalFiltered,
        tasksOpportunityNowFiltered,
        tasksOverTheHorizonFiltered,
        tasksParkingLotFiltered,
        tasksSchedulingIssues,
    ]);

    const getLeftBorderColor = (filter) => {
        const colors = {
            TOTAL_TASKS: 'border-gray-500',
            TODAYS_TASKS: 'border-green-700',
            PAST_DUE: 'border-red-500',
            DUE_TODAY: 'border-yellow-500',
            CRITICAL: 'border-myncritical-400',
            OPPORTUNITY_NOW: 'border-mynopportunitynow-400',
            OVER_THE_HORIZON: 'border-mynoverthehorizon-400',
            PARKING_LOT: 'border-mynparkinglot-400',
            SCHEDULING_ISSUES: 'border-red-400',
        };

        return colors[filter];
    };

    const filters = [
        'TOTAL_TASKS',
        'TODAYS_TASKS',
        'PAST_DUE',
        'DUE_TODAY',
        'CRITICAL',
        'OPPORTUNITY_NOW',
        'OVER_THE_HORIZON',
        'PARKING_LOT',
        'SCHEDULING_ISSUES',
    ];

    return (
        <div className="p-4">
            <div className="mb-4">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <h2 className="ml-2 text-lg font-bold uppercase font-sfProText">Overview</h2>
                </div>

                {filters.map((filter) => (
                    <div
                        key={filter}
                        className={`pl-2 mt-4 border-l-4 cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${getLeftBorderColor(filter)}`}
                        onClick={() => handleFilterClick(filter)}
                    >
                        <h3
                            className={`text-xs uppercase ${filter === selectedFilter ? 'border-b-2' : ''} ${getTextColor(filter)}`}
                        >
                            {getFilterName(filter)}
                        </h3>
                        <p className="text-4xl font-light font-sfProText">{getFilterCount(filter)}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Overview;
