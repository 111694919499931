import React from 'react';
import { motion } from 'framer-motion';
import BaseThemeComponent from './BaseThemeComponent';
const CustomerLoadingScreen = React.memo(() => {
    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 50
            }
        }
    };

    return (
        <BaseThemeComponent>
            <motion.div variants={itemVariants} className="mt-8 text-center">
                <div className="inline-block w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
                <p className="mt-4 text-xl font-semibold text-blue-800">Loading...</p>
            </motion.div>
        </BaseThemeComponent>
    );
});

export default CustomerLoadingScreen;