import { useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { debounce } from 'lodash';

const isMobileDevice = () => {
    if (typeof window === 'undefined') return false;
    return Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios' || window.innerWidth < 768;
};

const isSmallCalendarDevice = () => {
    if (typeof window === 'undefined') return false;
    return window.innerWidth < 1800;
};

export const useDeviceType = () => {
    const [isMobile, setIsMobile] = useState(isMobileDevice());
    const [smallCalendar, setSmallCalendar] = useState(isSmallCalendarDevice());

    useEffect(() => {
        const handleResize = debounce(() => {
            setIsMobile(
                Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios' || window.innerWidth < 768
            );
            setSmallCalendar(isSmallCalendarDevice);
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => {
            handleResize.cancel();
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { isMobile, smallCalendar };
};
