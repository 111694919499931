import React, { createContext, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { Task } from '../domain/Task';
import { useCustomer } from '../providers/CustomerProvider';
import { useTaskActions } from '../components/tasks/hooks/useTaskActions';
import { useSchedules } from '../components/utils/scheduleApi';

const TaskModalContext = createContext();

export const TaskModalProvider = ({ children, refetchTasks, saveTask }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentTask, setCurrentTask] = useState(null);
    const { customer } = useCustomer();
    const { updateTask } = useTaskActions();
    const { schedules } = useSchedules();

    const openTaskModal = useCallback(
        (existingTask = null) => {
            if (existingTask) {
                setCurrentTask(existingTask);
            } else if (customer) {
                const newTask = new Task(customer.id);
                newTask.id = 'new';
                newTask.isNewTask = true;
                newTask.isRecurring = true;
                newTask.isAutoScheduled = true;

                // Set default schedule if 'Weekdays' schedule exists
                const weekdaysSchedule = schedules.find((schedule) => schedule.name === 'Weekdays');
                if (weekdaysSchedule) {
                    newTask.selectedSchedule = weekdaysSchedule.id;
                    newTask.schedules = [weekdaysSchedule];
                }

                setCurrentTask(newTask);
                console.log('New task created:', newTask);
            } else {
                console.error('Customer is not available');
                return;
            }
            setIsOpen(true);
            console.log('isOpen set to true');
        },
        [customer, schedules]
    );

    const closeTaskModal = useCallback(() => {
        setIsOpen(false);
        setCurrentTask(null);
    }, []);

    const handleUpdateTask = useCallback(
        async (updatedTask) => {
            console.log('handleUpdateTask called with:', updatedTask);
            try {
                await saveTask(updatedTask);
                refetchTasks();
                closeTaskModal();
            } catch (error) {
                console.error('Failed to save task:', error);
            }
        },
        [saveTask, refetchTasks, closeTaskModal]
    );

    useEffect(() => {}, [isOpen, currentTask]);

    const value = useMemo(
        () => ({
            isOpen,
            currentTask,
            openTaskModal,
            closeTaskModal,
            updateTask,
            handleUpdateTask,
        }),
        [isOpen, currentTask, openTaskModal, closeTaskModal, updateTask, handleUpdateTask]
    );

    return <TaskModalContext.Provider value={value}>{children}</TaskModalContext.Provider>;
};

export const MemoizedTaskModalProvider = React.memo(TaskModalProvider);

export const useTaskModal = () => {
    const context = useContext(TaskModalContext);
    if (context === undefined) {
        throw new Error('useTaskModal must be used within a TaskModalProvider');
    }
    return context;
};
